<template>
  <TheContactForm />
</template>
<script>
export default {
  components: {
    TheContactForm: () => import("@/components/global/TheContactForm.vue")
  }
};
</script>
